<style scoped>
    .content {
        width: 1320px;
        padding: 185px 20px;
        margin: 0 auto;
    }

    .con-p1 {
        text-align: center;
        font-size: 22px;
        color: #222;
    }

    .con-p2 {
        font-size: 14px;
        colro: #666;
        margin: 62px 0 80px;
        text-align: center;
    }

    .con-div {
        padding: 40px 0;
        border-top: 1px solid #D5D5D5;
        border-bottom: 1px solid #D5D5D5;
    }

    .con-div img {
        width: 100%;
        height: 482px;
    }

    .con-p3 {
        line-height: 35px;
        margin-bottom: 35px;
    }

    .con-div2 {
        margin-top: 50px;
        color: #666A75;
    }

    .con-p3:nth-child(1) {
        text-indent: 2em;
    }
</style>

<template>
    <div>
        <Top :topTitle="topTitle"></Top>
        <div class="content">
            <p class="con-p1">{{ info.title }}</p>
            <p class="con-p2">发表于{{ info.create_time }}</p>
            <div class="con-div">
                <div class="con-p3" v-html="info.content">
                    
                </div>
                <div v-if="info.info_image">
                    <img :src="item" v-for="(item, index) in info.info_image" :key="index"/>
                </div>
            </div>
            <div class="con-div2 flex-between">
                <p @click="toPath(prev.id)">上一篇：{{ prev.title }}</p>
                <p @click="toPath(next.id)">下一篇：{{ next.title }}</p>
            </div>

        </div>
        <Bottom :minWidth="minWidth"></Bottom>
    </div>
</template>

<script>
    import Top from "../../components/top.vue";
    import Bottom from "../../components/bottom.vue";
    export default {
        data() {
            return {
                type: 1,
                topTitle: "资讯动态",
                minWidth: 1320,
                info: {
                    title: "",
                    image: "",
                    introduction: "",
                    content: "",
                    web_title: "",
                    web_keywords: "",
                    web_description: "",
                    store_name: "",
                    store_logo: "",
                },
                prev: [],
                next: [],
            }
        },
        created(){
            this.type = this.$route.query.type;
            this.getInfo(this.$route.query.id);
            this.getPrevNext(this.$route.query.id);
        },
        methods: {
            getInfo(id){
                this.http({
                    method: "get",
                    url: this.api.getNewsInfo,
                    data: {id},
                }).then(res => {
                    this.info = res.data;
                document.title = res.data.web_title ? "佑护科技 " + res.data.web_title : "佑护科技 资讯动态 " + res.data.title;
                if (res.data.web_keywords) {
                    document.querySelector('meta[name="keywords"]').setAttribute("content", res.data.web_keywords);
                }
                if (res.data.web_description) {
                    document.querySelector('meta[name="description"]').setAttribute("content", res.data.web_description);
                }
            })
            },
            getPrevNext(id){
                this.http({
                    method: "get",
                    url: this.api.getNewsPrev,
                    data: {id, type: this.type},
                }).then(res => {
                    this.prev = res.data.prev;
                this.next = res.data.next;
            })
            },
            toPath(id) {
                if (id) {
                    this.$router.push("/newDetail?id=" + id + "&type=" + this.type);
                    location.reload();
                }
            }
        },
        components: {Top, Bottom}
    }
</script>

